<template>
  <div id="container">
    <ion-card>
      <ion-card-header
        style="display: flex; justify-content: space-between; align-items: center; height: 40px;"
      >
        <ion-card-subtitle>Presença confirmada</ion-card-subtitle>
        <div v-if="accessLevel < 5">
          <ion-icon
            :icon="settings"
            style="font-size: 25px;"
            @click="setOpenModalConfirmationPlayers()"
          ></ion-icon>
        </div>
      </ion-card-header>

      <ion-card-content>
        <div
          v-if="loading"
          style="display: flex; width: 100%; justify-content: center; padding: 40px;"
        >
          <ion-spinner :color="theme"></ion-spinner>
        </div>
        <div
          style="overflow-x: auto;"
          :style="allConfirmedPlayers.length > 0 ? 'max-height: 185px;' : ''"
          v-else
        >
          <h2
            v-if="!allConfirmedPlayers.length > 0"
            style="width: 100%; text-align: center; padding-top: 25px; padding-bottom: 9px;"
          >
            Nenhum jogador confirmado
          </h2>
          <div v-else class="container-chips">
            <ion-chip
              v-for="item in allConfirmedPlayers.sort((a, b) =>
                a.apelido > b.apelido ? 1 : -1
              )"
              :key="item.cdId"
            >
              <Avatar
                class="avatar"
                width="35"
                height="35"
                :imageUrl="item.imagemUrl"
                eventOn
                @onClick="setOpenModalPlayerProfile(item.cdId)"
              />
              <ion-label style="max-width: 200px;"
                ><h2 class="nickname-color">{{ item.apelido }}</h2></ion-label
              >
              <ion-icon
                v-if="accessLevel < 3"
                :icon="closeCircle"
                color="danger"
                @click.stop="removePlayer(item.cdId)"
              ></ion-icon>
            </ion-chip>
          </div>
        </div>
      </ion-card-content>
    </ion-card>

    <ion-toast
        :is-open="isOpenToast"
        color="danger"
        :message="message"
        :duration="2000"
        @onDidDismiss="isOpenToast = false"
      >
      </ion-toast>
  </div>
</template>

<script>
import { IonToast } from "@ionic/vue";
import Avatar from "@/components/Layout/Avatar.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";

export default {
  name: "ConfirmedAttendanceList",
  mixins: [requestManager, computedManager],
  components: {
    IonToast,
    Avatar,
  },
  props: {
    refresh: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpenToast: false,
      message: "",
      loading: false,
      playerId: 0,
    };
  },
  computed: {
    allConfirmedPlayers() {
      return [...this.$store.state.base.allConfirmedPlayers];
    },
    teamList() {
      return this.$store.state.base.teamList;
    },
    alreadySelectedPlayerIds() {
      return this.teamList
        .map((p) => p.players)
        .flat()
        .map((e) => e.cdId);
    },
  },
  methods: {
    async init() {
      this.loading = true;
      const response = await this.genericRequest(
        "common/getAllConfirmedPlayer",
        {
          metric: "4",
        }
      );
      if (typeof response != "string") {
        this.$store.commit("base/setAllConfirmedPlayers", response);
      } else {
        this.$store.commit("base/setAllConfirmedPlayers", []);
      }
      this.loading = false;
    },
    async removePlayer(pPlayerId) {
      if (this.alreadySelectedPlayerIds.includes(pPlayerId)) {
        this.message =
          "Jogador não pode ser removido pois está incluído em um time.";
        this.isOpenToast = true;
        return;
      }

      this.loading = true;
      const allConfirmedPlayers = this.allConfirmedPlayers.filter(
        (e) => e.cdId != pPlayerId
      );
      this.$store.commit("base/setAllConfirmedPlayers", allConfirmedPlayers);
      const response = await this.genericRequest("common/saveConfirmedList", {
        allConfirmedPlayers: this.allConfirmedPlayers.map((e) => e.cdId),
      });
      if (response != "Sucesso") {
        this.message = response;
        this.isOpenToast = true;
      }
      this.loading = false;
    },
    setOpenModalConfirmationPlayers() {
      this.$store.commit("base/addShowModal", "ModalConfirmationPlayers");
    },
    setOpenModalPlayerProfile(pPlayerId) {
      this.$store.commit("base/addPropsModal", {
        name: "ModalPlayerProfile",
        prop: pPlayerId,
      });

      this.$store.commit("base/addShowModal", "ModalPlayerProfile");
    },
  },
  watch: {
    async refresh() {
      await this.init();
    },
  },
};
</script>

<style scoped>
h2 {
  margin: 0;
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

h1 {
  margin: 0;
  font-size: 15px;
}

.avatar {
  margin-right: 10px;
}

.container-chips {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 10px;
  max-height: 185px;
  padding-bottom: 10px;
}
</style>
