<template>
  <div id="container">
    <ion-card class="custom-ion-card">
      <ion-card-header
        style="display: flex; justify-content: space-between; align-items: center; height: 40px;"
      >
        <ion-card-subtitle style="display: flex; align-items: center;"
          >Partidas de&nbsp;
          <ion-select
            class="ion-no-padding"
            style="text-transform: lowercase;"
            :value="selectedDay"
            :disabled="loading"
            interface="popover"
            @ionChange="selectedDay = $event.target.value"
          >
            <template v-for="value in [7, 6, 5, 4, 3]" :key="value">
              <ion-select-option :value="value">{{
                getDate(value)
              }}</ion-select-option>
            </template>
            <ion-select-option value="2">Anteontem</ion-select-option>
            <ion-select-option value="1">Ontem </ion-select-option>
            <ion-select-option value="0">Hoje</ion-select-option>
          </ion-select></ion-card-subtitle
        >
        <div>
          <ion-icon
            :icon="football"
            v-if="!loading"
            style="font-size: 25px;"
            @click="setOpenModalMatchesList()"
          ></ion-icon>
          <ion-icon
            :icon="time"
            v-if="
              accessLevel < 4 &&
                (playerInformation?.cdId == matchSummary?.owner ||
                  (accessLevel == 1 && matchSummary?.owner)) &&
                !loading
            "
            style="font-size: 25px; margin-right: 20px; margin-left: 20px;"
            @click="isOpenActionSheetFinish = true"
          ></ion-icon>
          <ion-icon
            :icon="settings"
            v-if="
              accessLevel < 4 &&
                (playerInformation?.cdId == matchSummary?.owner ||
                  (accessLevel == 1 && matchSummary?.owner)) &&
                !loading
            "
            style="font-size: 25px;"
            @click="setOpenModalMatchManager()"
          ></ion-icon>
        </div>
      </ion-card-header>

      <ion-card-content>
        <div
          v-if="loading"
          style="display: flex; width: 100%; justify-content: center; padding: 40px;"
        >
          <ion-spinner :color="theme"></ion-spinner>
        </div>
        <div v-else>
          <div v-if="matchSummary.cdIdJogo == null">
            <h2>
              Nenhuma partida ocorrendo
            </h2>

            <div
              class="create-match"
              v-if="accessLevel < 4"
              :style="getBackgroundColor(theme)"
              @click="setOpenModalCreateMatch()"
            >
              Criar partida
            </div>
          </div>
          <div v-else>
            <div class="current-football-match-card">
              <p
                style="display: flex; justify-content: center; font-size: 20px; padding: 10px;"
              >
                <span style="margin-left: 10px; margin-right: 10px;">{{
                  matchSummary.team1
                }}</span
                >x
                <span style="margin-left: 10px; margin-right: 10px;">{{
                  matchSummary.team2
                }}</span>
              </p>
              <div class="match-teams">
                <div class="team" style="border-right: 1px solid gray;">
                  <template
                    v-for="item in matchSummary.playersTeamOne.sort((a, b) =>
                      a.apelido > b.apelido ? 1 : -1
                    )"
                    :key="item.cdId"
                  >
                    <Avatar
                      class="avatar"
                      width="50"
                      height="50"
                      :imageUrl="item.imagemUrl"
                      eventOn
                      @onClick="setOpenModalPlayerProfile(item.cdId)"
                    />
                  </template>
                </div>

                <div class="team">
                  <template
                    v-for="item in matchSummary.playersTeamTwo.sort((a, b) =>
                      a.apelido > b.apelido ? 1 : -1
                    )"
                    :key="item.cdId"
                  >
                    <Avatar
                      class="avatar"
                      width="50"
                      height="50"
                      :imageUrl="item.imagemUrl"
                      eventOn
                      @onClick="setOpenModalPlayerProfile(item.cdId)"
                    />
                  </template>
                </div>
              </div>
            </div>
            <div
              class="more-information"
              :style="getBackgroundColor(theme)"
              @click="setOpenModalMatchInformation()"
            >
              Mostrar mais informações
            </div>
          </div>
        </div>
      </ion-card-content>
    </ion-card>

    <ion-alert
      :is-open="isOpenDeleteAlert"
      header="Deletar partida"
      message="Deletando a partida todos os eventos vinculados a ela serão perdidos."
      :buttons="buttonsDeleteAlert"
      @onDidDismiss="setOpenDeleteAlert(false)"
    >
    </ion-alert>

    <ion-action-sheet
      :is-open="isOpenActionSheetFinish"
      header="Opções"
      :buttons="buttonsFinish"
      @onDidDismiss="isOpenActionSheetFinish = false"
    >
    </ion-action-sheet>

    <ion-toast
      :is-open="isOpenToast"
      color="danger"
      :message="message"
      :duration="2000"
      @onDidDismiss="isOpenToast = false"
    >
    </ion-toast>
  </div>
</template>

<script>
import { IonActionSheet, IonAlert, IonToast } from "@ionic/vue";
import Avatar from "@/components/Layout/Avatar.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";

export default {
  name: "CurrentFootballMatch",
  mixins: [requestManager, computedManager],
  components: {
    Avatar,
    IonActionSheet,
    IonAlert,
    IonToast,
  },
  props: {
    refresh: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedDay: "0",
      isOpenToast: false,
      isOpenDeleteAlert: false,
      isOpenActionSheetFinish: false,
      message: "",
      buttonsDeleteAlert: [
        {
          text: "Cancelar",
          icon: this.close,
          role: "cancel",
          handler: () => {},
        },
        {
          text: "Confirmar",
          role: "destructive",
          icon: this.trash,
          handler: () => {
            this.deleteMatch();
          },
        },
      ],
      buttonsFinish: [
        {
          text: "Finalizar partida",
          role: "destructive",
          cssClass: "end-game",
          icon: this.checkmarkCircle,
          handler: () => {
            this.endMatch();
          },
        },
        {
          text: "Deletar partida",
          role: "destructive",
          cssClass: "close",
          icon: this.trash,
          handler: () => {
            this.setOpenDeleteAlert(true);
          },
        },
        {
          text: "Cancelar",
          icon: this.close,
          cssClass: "close",
          role: "cancel",
          handler: () => {},
        },
      ],
      hasData: false,
      loading: false,
      isOpenModalMatchesList: false,
      isOpenModalPlayerProfile: false,
      playerId: null,
      defaultValue: {
        cdIdJogo: null,
        changed: null,
        playersTeamOne: [],
        playersTeamTwo: [],
        team1: null,
        team2: null,
      },
    };
  },
  computed: {
    matchSummary() {
      return this.$store.state.base.matchSummary;
    },
    propsModal() {
      return this.$store.state.base.propsModal;
    },
    reload() {
      return this.$store.state.base.reloadCurrentMatch;
    },
  },
  methods: {
    async init() {
      this.loading = true;
      const response = await this.genericRequest("common/getCurrentMatch");
      if (typeof response != "string") {
        this.$store.commit("base/setMatchSummary", response);
      } else {
        this.$store.commit("base/setMatchSummary", this.defaultValue);
      }
      this.loading = false;
    },
    getDate(pValue) {
      let d = new Date();
      d.setDate(d.getDate() - pValue);
      return d.toLocaleDateString("pt-br");
    },
    getBackgroundColor(pClassName) {
      if (pClassName == "primary") {
        return "background-color: #3880e6;";
      } else if (pClassName == "secondary") {
        return "background-color: #3dc2ff;";
      } else if (pClassName == "tertiary") {
        return "background-color: #5260ff;";
      } else if (pClassName == "success") {
        return "background-color: #2dd36f;";
      } else if (pClassName == "warning") {
        return "background-color: #ffc409;";
      } else if (pClassName == "danger") {
        return "background-color: #eb445a;";
      }
    },
    setOpenModalPlayerProfile(pPlayerId) {
      this.$store.commit("base/addPropsModal", {
        name: "ModalPlayerProfile",
        prop: pPlayerId,
      });

      this.$store.commit("base/addShowModal", "ModalPlayerProfile");
    },
    setOpenModalMatchManager() {
      this.$store.commit("base/addPropsModal", {
        name: "ModalMatchManager",
        prop: this.matchSummary,
      });

      this.$store.commit("base/addShowModal", "ModalMatchManager");
    },
    setOpenModalCreateMatch() {
      this.$store.commit("base/addShowModal", "ModalCreateMatch");
    },
    setOpenModalMatchInformation() {
      this.$store.commit("base/addShowModal", "ModalMatchInformation");
    },
    setOpenModalMatchesList() {
      this.$store.commit("base/addPropsModal", {
        name: "ModalMatchesList",
        prop: this.selectedDay,
      });

      this.$store.commit("base/addShowModal", "ModalMatchesList");
    },
    setOpenDeleteAlert(pState) {
      this.isOpenDeleteAlert = pState;
    },
    async endMatch() {
      this.loading = true;

      const team1 = this.matchSummary.playersTeamOne.map((e) => {
        return { cdIdPlayer: e.cdId, chosenTeam: 1 };
      });

      const team2 = this.matchSummary.playersTeamTwo.map((e) => {
        return { cdIdPlayer: e.cdId, chosenTeam: 2 };
      });

      const response = await this.genericRequest("common/endMatch", {
        token: this.token,
        cdIdOwner: this.playerInformation.cdId,
        cdIdMatch: this.matchSummary.cdIdJogo,
        players: [...team1, ...team2],
      });

      if (response.message != "Sucesso") {
        this.message = response;
        this.isOpenToast = true;
      } else {
        if (response?.lastWinner) {
          this.$store.commit("base/setLastWinner", response.lastWinner);
        }
        this.resetMatch();
      }

      this.loading = false;
    },
    async deleteMatch() {
      this.loading = true;
      const response = await this.genericRequest("common/deleteMatch", {
        token: this.token,
        cdIdOwner: this.playerInformation.cdId,
        cdIdMatch: this.matchSummary.cdIdJogo,
      });

      if (response != "Sucesso") {
        this.message = response;
        this.isOpenToast = true;
      } else {
        this.resetMatch();
      }

      this.loading = false;
    },
    resetMatch() {
      this.$store.commit("base/setInformationMatch", {
        events: [],
        owner: null,
        cdIdMatch: null,
      });

      this.$store.commit("base/setMatchSummary", this.defaultValue);
      this.$store.commit("base/setAllMatchesDay", []);
      this.$store.commit("base/setReloadCurrentMatch", Date.now());
    },
  },
  watch: {
    async refresh() {
      await this.init();
    },
    reload(pNewValue) {
      if (pNewValue > 0) {
        if (pNewValue == 10) this.selectedDay = "0";
        this.init();
      }
    },
    matchSummary: {
      deep: true,
      handler(pNewVal) {
        if (pNewVal && pNewVal.cdIdJogo) {
          this.hasData = true;
        } else {
          this.hasData = false;
        }
      },
    },
    selectedDay() {
      this.$store.commit("base/setAllMatchesDay", []);
    },
  },
};
</script>

<style scoped>
h2 {
  margin: 0;
  color: #737373;
  font-size: 1rem;
}

.no-current-football-match {
  margin: auto;
  height: 70px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000000cc;
  width: 90%;
  margin-top: 15px;
}

.current-football-match-card {
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}

.custom-ion-card h2 {
  width: 100%;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
}

.custom-ion-card ion-card-content {
  padding: 0;
}

.match-teams {
  display: flex;
  justify-content: space-around;
  display: flex;
  align-items: stretch;
  padding-bottom: 20px;
  padding-top: 10px;
}

.team {
  display: flex;
  justify-content: center;
  width: 50%;
  padding-left: 15px;
  flex-wrap: wrap;
}

.more-information {
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  padding: 10px;
}

.create-match {
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  padding: 10px;
}

.avatar {
  border-radius: 50%;
  border: 3px solid white;
  margin-left: -15px;
  background-color: white;
}
</style>
