<template>
  <ion-card v-if="type == 'highlights'">
    <ion-card-header>
      <ion-card-subtitle style="font-weight: bold;" :style="getColor(title)">{{
        title
      }}</ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      {{ getMessage(title) }}
    </ion-card-content>
  </ion-card>

  <ion-card v-if="type == 'profile'">
    <ion-card-header>
      <ion-card-subtitle
        >{{ title }}
        <ion-icon
          v-if="loading"
          :color="theme"
          :icon="save"
          @click="loading = !loading"
        ></ion-icon>
        <ion-spinner
          v-else
          :color="theme"
          @click="loading = !loading"
        ></ion-spinner>
      </ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      <ion-input placeholder="Digite ..."></ion-input>
    </ion-card-content>
  </ion-card>

  <ion-card v-if="type == 'validation'">
    <ion-card-header style="background-color: #eb445a;">
      <ion-card-subtitle color="danger" style="font-weight: bold; color: white;"
        >{{ title }}
      </ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      {{ message }}
    </ion-card-content>
  </ion-card>

  <ion-card v-if="type == 'teamName'">
    <ion-card-header>
      <ion-card-subtitle style="font-weight: bold;"
        >{{ title }}
      </ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      {{ message }}
    </ion-card-content>
  </ion-card>
</template>

<script>
import { save } from "ionicons/icons";

export default {
  name: "Popover",
  props: {
    title: {
      type: String,
      required: true,
    },
    pontos: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
    },
    message: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      nome: "",
      apelido: "",
      descricao: "",
      melhorPe: "",
      timeQueTorce: "",
    };
  },
  computed: {
    save() {
      return save;
    },

    theme() {
      return "primary";
    },
  },
  methods: {
    getColor(pType) {
      if (pType == "Artilheiro") {
        return "color: #34A853;";
      } else if (pType == "Vitorioso") {
        return "color: #fa7b17;";
      } else if (pType == "Assistente") {
        return "color:  #6b35ea;";
      } else if (pType == "Penalteiro") {
        return "color: #ea4335;";
      } else if (pType == "Bruxo") {
        return "color: #3595ea;";
      } else if (pType == "Eficiente") {
        return localStorage.getItem("theme")
          ? "color: #bebebe;"
          : "color: #3c3c3c;";
      }
    },
    getMessage(pType) {
      if (pType == "Artilheiro") {
        return this.pontos > 1
          ? `Jogador fez ${this.pontos} gols nos últimos dias.`
          : `Jogador fez ${this.pontos} gol nos últimos dias.`;
      } else if (pType == "Vitorioso") {
        return this.pontos > 1
          ? `Jogador ganhou ${this.pontos} jogos nos últimos dias.`
          : `Jogador ganhou ${this.pontos} jogo nos últimos dias.`;
      } else if (pType == "Assistente") {
        return this.pontos > 1
          ? `Jogador realizou ${this.pontos} assistências nos últimos dias.`
          : `Jogador realizou ${this.pontos} assistência nos últimos dias.`;
      } else if (pType == "Penalteiro") {
        return this.pontos > 1
          ? `Jogador fez ${this.pontos} gols de penaltis nos últimos dias.`
          : `Jogador fez ${this.pontos} gol de penaltis nos últimos dias.`;
      } else if (pType == "Bruxo") {
        const [vitorias, partidas, aproveitamento] = this.pontos.split(":");
        return `Jogador possui o aproveitamento de ${aproveitamento}%, com ${
          vitorias > 1 ? vitorias + " vitórias" : vitorias + " vitória"
        } em ${
          partidas > 1 ? partidas + " jogos" : partidas + " jogo"
        } nos últimos dias.`;
      } else if (pType == "Eficiente") {
        const [valores, partidas, eficiencia] = this.pontos.split(":");
        return `Jogador possui a eficiência de ${
          eficiencia > 100 ? "100" : eficiencia
        }%, com ${
          valores > 1
            ? valores + " assitências/gols/gols de pênaltis"
            : valores + " assitência/gol/gol de pênalti"
        } em ${
          partidas > 1 ? partidas + " jogos" : partidas + " jogo"
        } nos últimos dias.`;
      }
    },
  },
};
</script>

<style scoped>
ion-icon {
  font-size: 20px;
}

ion-spinner {
  width: 20px;
  height: 20px;
}

ion-card {
  margin: 0;
  width: 100%;
}

ion-card-subtitle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

ion-card-content {
  padding: 10px;
}

ion-input {
  padding-right: 10px !important;
}
</style>
