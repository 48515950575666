<template>
  <div id="container">
    <ion-card class="custom-ion-card">
      <ion-card-header>
        <ion-card-subtitle>Destaques da semana</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content class="custom-ion-card-content">
        <div
          v-if="loading"
          style="display: flex; width: 100%; justify-content: center; padding: 64.5px;"
        >
          <ion-spinner :color="theme"></ion-spinner>
        </div>
        <div v-else>
          <div v-if="!highlights.length">
            <h2>
              Nenhum destaque na semana
            </h2>
          </div>

          <div class="highlights-information" v-else>
            <ion-card
              class="custom-ion-card-child"
              v-for="item in highlights"
              :key="item.cdId"
            >
              <ion-card-content>
                <Avatar
                  class="avatar"
                  width="70"
                  height="70"
                  :imageUrl="item.imagemUrl"
                  eventOn
                  @onClick="setOpenModalPlayerProfile(item.cdId)"
                />
                <p
                  style="text-align: center; margin-top: 5px; margin-bottom: 5px;"
                >
                  {{ item.apelido }}
                </p>
                <h1
                  @click="
                    setOpenPopover(true, $event, item.destaque, item.pontos)
                  "
                  :style="getBackgroundColor(item.destaque)"
                >
                  {{ item.destaque }}
                </h1>
              </ion-card-content>
            </ion-card>
          </div>
        </div>
      </ion-card-content>
    </ion-card>

    <ion-popover
      :is-open="isOpenRef"
      :event="event"
      :translucent="true"
      @onDidDismiss="setOpenPopover(false)"
    >
      <Popover :title="title" type="highlights" :pontos="pontos"></Popover>
    </ion-popover>
  </div>
</template>

<script>
import { IonPopover } from "@ionic/vue";
import Avatar from "@/components/Layout/Avatar.vue";
import Popover from "@/components/Popover.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";

export default {
  name: "Highlights",
  mixins: [requestManager, computedManager],
  components: {
    IonPopover,
    Avatar,
    Popover,
  },
  props: {
    refresh: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpenRef: false,
      title: "",
      pontos: 0,
      highlight: "",
      loading: false,
    };
  },
  computed: {
    highlights() {
      const highlights = [...this.$store.state.base.highlights];
      return highlights.sort(() => Math.random() - 0.5);
    },
  },
  methods: {
    async init() {
      this.loading = true;
      const response = await this.genericRequest("common/getHighlights");
      if (typeof response != "string") {
        this.$store.commit("base/setHighlights", response);
      } else {
        this.$store.commit("base/setHighlights", []);
      }
      this.loading = false;
    },
    getBackgroundColor(pType) {
      if (pType == "Artilheiro") {
        return "background-color: #34A853;";
      } else if (pType == "Vitorioso") {
        return "background-color: #fa7b17;";
      } else if (pType == "Assistente") {
        return "background-color:  #6b35ea;";
      } else if (pType == "Penalteiro") {
        return "background-color: #ea4335;";
      } else if (pType == "Bruxo") {
        return "background-color: #3595ea;";
      } else if (pType == "Eficiente") {
        return "background-color:  #3c3c3c;";
      }
    },
    setOpenPopover(pState, pEvent, pTitle, pPontos) {
      this.event = pEvent;
      this.title = pTitle;
      this.pontos = pPontos;
      this.isOpenRef = pState;
    },
    setOpenModalPlayerProfile(pPlayerId) {
      this.$store.commit("base/addPropsModal", {
        name: "ModalPlayerProfile",
        prop: pPlayerId,
      });

      this.$store.commit("base/addShowModal", "ModalPlayerProfile");
    },
  },
  watch: {
    async refresh() {
      await this.init();
    },
  },
};
</script>

<style scoped>
h2 {
  margin: 0;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 8px;
  color: #737373;
  font-size: 1rem;
}

img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 3px solid white;
}

.custom-ion-card-child {
  width: 130px;
  margin: auto;
  background-color: white;
  margin: 5px;
}

.custom-ion-card-content {
  display: block;
  overflow: auto;
  margin-left: 16px;
  margin-right: 16px;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 16px;
}

.custom-ion-card-content h1 {
  font-size: 14px;
  text-align: center;
  width: 100%;
  color: white;
  margin-bottom: 0;
  padding: 5px;
}

.highlights-information {
  overflow-x: auto;
  display: inline-flex;
}

.highlights-information ion-card-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0 0 0;
  height: 100%;
}
</style>
