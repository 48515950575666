<template>
  <div id="container">
    <ion-card>
      <ion-card-header
        style="display: flex; justify-content: space-between; align-items: center; height: 40px;"
      >
        <ion-card-subtitle style="display: flex; align-items: center;"
          >Resumo de&nbsp;
          <ion-select
            class="ion-no-padding"
            style="text-transform: lowercase;"
            :value="selectedDay"
            :disabled="loading"
            interface="popover"
            @ionChange="selectedDay = $event.target.value"
          >
            <template v-for="value in [7, 6, 5, 4, 3]" :key="value">
              <ion-select-option :value="value">{{
                getDate(value)
              }}</ion-select-option>
            </template>
            <ion-select-option value="2">Anteontem</ion-select-option>
            <ion-select-option value="1">Ontem </ion-select-option>
            <ion-select-option value="0">Hoje</ion-select-option>
          </ion-select></ion-card-subtitle
        >
      </ion-card-header>

      <ion-card-content>
        <div
          v-if="loading"
          style="display: flex; width: 100%; justify-content: center; padding: 40px;"
        >
          <ion-spinner :color="theme"></ion-spinner>
        </div>
        <div v-else>
          <ion-select
            class="ion-no-padding"
            style="padding-top: 10px; padding-bottom: 10px;"
            :value="metric"
            interface="popover"
            @ionChange="metric = $event.target.value"
          >
            <ion-select-option value="4">Gols</ion-select-option>
            <ion-select-option value="5">Assistências</ion-select-option>
            <ion-select-option value="1">Vitórias</ion-select-option>
            <ion-select-option value="2">Derrotas</ion-select-option>
          </ion-select>
          <h2 v-if="!podiumList.length > 0">
            Nenhum dado encontrado
          </h2>
          <div style="overflow-x: auto;" v-else>
            <div class="container-podium">
              <div
                class="container-podium-bar"
                v-for="item in podiumList"
                :key="item.cdId"
              >
                <div
                  :class="
                    metric == '2'
                      ? `podium-bar-lose podium-bar-lose-${item.rakingPosition}`
                      : `podium-bar podium-bar-${item.rakingPosition}`
                  "
                >
                  <div>
                    <p>
                      {{ item.rakingPosition + "º" }}
                    </p>

                    <Avatar
                      class="avatar"
                      width="50"
                      height="50"
                      :imageUrl="item.imagemUrl"
                      eventOn
                      @onClick="setOpenModalPlayerProfile(item.cdId)"
                    />
                  </div>
                </div>
                <h1>
                  {{ getStringValue(item.qtdGols) }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import Avatar from "@/components/Layout/Avatar.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";

export default {
  name: "Podium",
  mixins: [requestManager, computedManager],
  components: { Avatar },
  props: {
    refresh: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      hasData: false,
      loading: false,
      selectedDay: "0",
      playerId: 0,
      metric: "4",
    };
  },
  computed: {
    podiumList() {
      return this.$store.state.base.podiumList;
    },
    reload() {
      return this.$store.state.base.reloadCurrentMatch;
    },
  },
  methods: {
    async init() {
      this.loading = true;
      const response = await this.genericRequest("common/getPodium", {
        metric: this.metric,
        selectedDay: this.selectedDay,
      });
      if (typeof response != "string") {
        this.$store.commit("base/setPodiumList", response);
      } else {
        this.$store.commit("base/setPodiumList", []);
      }
      this.loading = false;
    },
    getDate(pValue) {
      let d = new Date();
      d.setDate(d.getDate() - pValue);
      return d.toLocaleDateString("pt-br");
    },
    setOpenModalPlayerProfile(pPlayerId) {
      this.$store.commit("base/addPropsModal", {
        name: "ModalPlayerProfile",
        prop: pPlayerId,
      });

      this.$store.commit("base/addShowModal", "ModalPlayerProfile");
    },
    getStringValue(pValue) {
      if (this.metric == "4") {
        if (pValue > 1) {
          return pValue + " gols";
        } else {
          return pValue + " gol";
        }
      } else if (this.metric == "5") {
        if (pValue > 1) {
          return pValue + " assistên...";
        } else {
          return pValue + " assistên...";
        }
      } else if (this.metric == "1") {
        if (pValue > 1) {
          return pValue + " vitórias";
        } else {
          return pValue + " vitória";
        }
      } else if (this.metric == "2") {
        if (pValue > 1) {
          return pValue + " derrotas";
        } else {
          return pValue + " derrota";
        }
      }
    },
  },
  watch: {
    async refresh() {
      await this.init();
    },
    async metric() {
      await this.init();
    },
    async selectedDay() {
      await this.init();
    },
    reload(pNewValue) {
      if (pNewValue > 0) {
        this.init();
      }
    },
  },
};
</script>

<style scoped>
h2 {
  margin: 0;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #737373;
  font-size: 1rem;
}

h1 {
  margin: 0;
  font-size: 15px;
}

img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid white;
  background-color: white;
}

.container-podium {
  display: inline-flex;
  height: 200px;
  justify-content: space-around;
  align-items: flex-end;
  padding-bottom: 10px;
}

.container-podium-bar {
  width: 97.31px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.podium-bar {
  background-color: #737373;
  width: 68.09px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px 10px 5px 5px;
}

.podium-bar p {
  font-size: 25px;
  margin-top: -65px;
  text-align: center;
  height: 28px;
}

.podium-bar-1 {
  background: rgb(52, 168, 83);
  background: linear-gradient(
    0deg,
    rgba(52, 168, 83, 1) 60%,
    rgba(11, 84, 30, 1) 100%
  );
  height: 100px;
}

.podium-bar-1 p {
  color: #34a853;
}

.podium-bar-2 {
  background: rgb(250, 123, 23);
  background: linear-gradient(
    0deg,
    rgba(250, 123, 23, 1) 60%,
    rgba(164, 89, 30, 1) 100%
  );
  height: 60px;
}

.podium-bar-2 p {
  color: #fa7b17;
}

.podium-bar-3 {
  background: rgb(235, 68, 90);
  background: linear-gradient(
    0deg,
    rgba(235, 68, 90, 1) 60%,
    rgba(167, 53, 68, 1) 100%
  );
  height: 40px;
}

.podium-bar-3 p {
  color: #eb445a;
}

.podium-bar-lose {
  background: rgb(52, 168, 83);
  background: linear-gradient(
    0deg,
    rgba(52, 168, 83, 1) 60%,
    rgba(11, 84, 30, 1) 100%
  );
  width: 68.09px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px 10px 0px 0px;
}

.podium-bar-lose p {
  font-size: 25px;
  margin-top: -65px;
  text-align: center;
  height: 28px;
}

.podium-bar-lose-1 {
  background: rgb(235, 68, 90);
  background: linear-gradient(
    0deg,
    rgba(235, 68, 90, 1) 60%,
    rgba(167, 53, 68, 1) 100%
  );
  height: 100px;
}

.podium-bar-lose-1 p {
  color: #eb445a;
}

.podium-bar-lose-2 {
  background: rgb(250, 123, 23);
  background: linear-gradient(
    0deg,
    rgba(250, 123, 23, 1) 60%,
    rgba(164, 89, 30, 1) 100%
  );
  height: 60px;
}

.podium-bar-lose-2 p {
  color: #fa7b17;
}

.podium-bar-lose-3 {
  background: rgb(218, 186, 7);
  background: linear-gradient(
    0deg,
    rgba(218, 186, 7, 1) 60%,
    rgba(184, 160, 29, 1) 100%
  );
  height: 40px;
}

.podium-bar-lose-3 p {
  color: #daba07;
}

.container-podium-bar h1 {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.avatar {
  border-radius: 50%;
  border: 3px solid white;
}
</style>
