<template>
  <ion-page>
    <Header title="Dashboard" />
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" pull-min="10" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ConfirmedAttendanceList :refresh="refresh" />
      <Podium :refresh="refresh" />
      <TeamList :refresh="refresh" v-if="accessLevel < 3 || hasTeams" />
      <CurrentFootballMatch :refresh="refresh" />
      <Highlights :refresh="refresh" />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";

import Header from "@/components/Layout/Header.vue";
import { useRouter } from "vue-router";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";
import ConfirmedAttendanceList from "@/components/ConfirmedAttendanceList.vue";
import Podium from "@/components/Podium.vue";
import TeamList from "@/components/TeamList.vue";
import CurrentFootballMatch from "@/components/CurrentFootballMatch.vue";
import Highlights from "@/components/Highlights.vue";

export default {
  name: "Dashboard",
  mixins: [requestManager, computedManager],
  components: {
    IonContent,
    IonPage,
    Header,
    ConfirmedAttendanceList,
    Podium,
    TeamList,
    CurrentFootballMatch,
    Highlights,
    IonRefresher,
    IonRefresherContent,
  },
  data() {
    return {
      router: useRouter(),
      refresh: 0,
    };
  },
  computed: {
    playerInformation() {
      return this.$store.state.profile.playerInformation;
    },
    teamList() {
      return this.$store.state.base.teamList;
    },
    hasTeams() {
      return this.teamList.length > 0 && this.teamList[0].players.length > 0;
    },
  },
  mounted() {
    if (this.playerInformation) this.init();
  },
  methods: {
    init() {
      this.refresh = String(Date.now());
    },
    doRefresh(event) {
      this.init();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    },
  },
};
</script>
