<template>
  <div id="container">
    <ion-card>
      <ion-card-header
        style="display: flex; justify-content: space-between; align-items: center; height: 40px;"
      >
        <ion-card-subtitle>Times</ion-card-subtitle>
        <div v-if="accessLevel < 3">
          <ion-icon
            :icon="settings"
            style="font-size: 25px;"
            @click="setOpenModalModalCreateTeams()"
          ></ion-icon>
        </div>
      </ion-card-header>

      <ion-card-content>
        <div
          v-if="loading"
          style="display: flex; width: 100%; justify-content: center; padding: 40px;"
        >
          <ion-spinner :color="theme"></ion-spinner>
        </div>
        <div
          style="overflow-x: auto;"
          :style="hasTeams ? 'max-height: 185px;' : ''"
          v-else
        >
          <h2
            v-if="!hasTeams"
            style="width: 100%; text-align: center; padding-top: 25px; padding-bottom: 9px;"
          >
            Nenhum time montado
          </h2>
          <div
            v-else-if="hasTeams"
            style="display: flex; flex-direction: column; flex-wrap: wrap; width: 145px; max-height: 185px;"
          >
            <ion-card
              v-for="(team, index) in teamList"
              :key="index"
              style="margin-left: 10px;"
            >
              <ion-card-header
                @click="
                  setOpenPopover(
                    true,
                    $event,
                    `Time ${team.sequence}`,
                    team.teamName
                  )
                "
                style="display: flex; justify-content: center; padding: 5px !important"
                class="ion-no-padding"
              >
                <ion-card-subtitle>Time {{ team.sequence }}</ion-card-subtitle>
              </ion-card-header>
              <ion-card-content class="ion-no-padding">
                <div class="team" style="padding: 5px 0px 5px 15px;">
                  <template
                    v-for="item in team.players.sort((a, b) =>
                      a.apelido > b.apelido ? 1 : -1
                    )"
                    :key="item.cdId"
                  >
                    <Avatar
                      class="avatar"
                      width="50"
                      height="50"
                      :imageUrl="item.imagemUrl"
                      eventOn
                      @onClick="setOpenModalPlayerProfile(item.cdId)"
                    />
                  </template>
                </div>
              </ion-card-content>
            </ion-card>
          </div>
        </div>
      </ion-card-content>
    </ion-card>
    <ion-popover
      :is-open="isOpenRef"
      :event="event"
      :translucent="true"
      @onDidDismiss="setOpenPopover(false)"
    >
      <Popover :title="title" type="teamName" :message="teamName"></Popover>
    </ion-popover>
  </div>
</template>

<script>
import { IonPopover } from "@ionic/vue";
import Avatar from "@/components/Layout/Avatar.vue";
import Popover from "@/components/Popover.vue";
import requestManager from "@/mixins/RequestManager";
import computedManager from "@/mixins/ComputedManager.js";

export default {
  name: "TeamList",
  mixins: [requestManager, computedManager],
  components: {
    IonPopover,
    Avatar,
    Popover,
  },
  props: {
    refresh: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isOpenRef: false,
      title: "",
      message: "",
      teamName: "",
      loading: false,
      playerId: 0,
    };
  },
  computed: {
    teamList() {
      return this.$store.state.base.teamList;
    },
    hasTeams() {
      return this.teamList.length > 0 && this.teamList[0].players.length > 0;
    },
    reload() {
      return this.$store.state.base.reloadCurrentMatch;
    },
  },
  methods: {
    async init() {
      this.loading = true;
      const response = await this.genericRequest("common/getAllTeams");
      if (typeof response != "string") {
        this.$store.commit("base/setTeamList", response);
      } else {
        this.$store.commit("base/setTeamList", []);
      }
      this.loading = false;
    },
    setOpenModalPlayerProfile(pPlayerId) {
      this.$store.commit("base/addPropsModal", {
        name: "ModalPlayerProfile",
        prop: pPlayerId,
      });

      this.$store.commit("base/addShowModal", "ModalPlayerProfile");
    },
    setOpenModalModalCreateTeams() {
      this.$store.commit("base/addShowModal", "ModalCreateTeams");
    },
    setOpenPopover(pState, pEvent, pTitle, pMessage) {
      this.event = pEvent;
      this.title = pTitle;
      this.teamName = pMessage ? pMessage : "Nome não informado";
      this.isOpenRef = pState;
    },
  },
  watch: {
    async refresh() {
      await this.init();
    },
    reload(pNewValue) {
      if (pNewValue > 0) {
        this.init();
      }
    },
  },
};
</script>

<style scoped>
h2 {
  margin: 0;
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.avatar {
  border-radius: 50%;
  border: 3px solid white;
  margin-left: -15px;
  background-color: white;
}

.match-teams {
  display: flex;
  justify-content: space-around;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;
}

.team {
  display: flex;
  justify-content: center;
  padding-left: 15px;
  flex-wrap: wrap;
}
</style>
